import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { UserProvider } from './components/UserContext';
// import TopNav from './components/TopNav';
// import ChangeRequestForm from './components/changeRequest/ContributionForm';
// import AdminPanel from './components/adminPortal/AdminPanel';
// import Documentation from './components/documentation/DocumentationPage';
// import UserProfile from './components/userProfile/UserProfile';
// import PrivacyPolicy from './components/PrivacyPolicy';
import Footer from './components/Footer';
// import CookieConsent from './components/CookieConsent';
// import Marketplace from './components/marketplace/Marketplace.jsx';
// import Database from './components/database/Database'; 
// import SkillCalc from './components/skillCalc/SkillCalc';
// import InteractiveMap from './components/interactiveMap/InteractiveMap.jsx';
import Shutdown from './Shutdown.jsx';
import './App.css';
import './components/marketplace/marketplace.css';

const App = () => {
  return (
    <UserProvider>
      <HelmetProvider>
        <Helmet>
          <title>Verran Codex</title>
          <meta name="description" content="Welcome to Verran Codex, the comprehensive API documentation for Ashes of Creation." />
          <meta name="keywords" content="Ashes of Creation API, AOC API, Ashes Codex, Verran Codex" />
          <meta name="author" content="Verran Codex Team" />
          <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org",
                "@type": "WebSite",
                "name": "Verran Codex",
                "url": "https://verrancodex.com",
                "description": "Explore the comprehensive Ashes of Creation API documentation. Learn how to use and edit our endpoints and authenticate with your API key.",
                "publisher": {
                  "@type": "Organization",
                  "name": "Verran Codex"
                }
              }
            `}
          </script>
        </Helmet>
        <Router>
          <div className="App">
            
            <header className="App-header">
              {/* <TopNav /> */}
            </header>
            <div className="main-content">
              <Routes>
                <Route path="/" element={<Shutdown />} />
                {/* <Route path="/" element={<Documentation />} />
                <Route path="/documentation" element={<Documentation />} />
                <Route path="/contribution" element={<ChangeRequestForm />} />
                <Route path="/admin" element={<AdminPanel />} />
                <Route path="/profile" element={<UserProfile />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/marketplace" element={<Marketplace />} />
                <Route path="/database" element={<Database />} />  
                <Route path="/skill-calc" element={<SkillCalc />} /> 
                <Route path="/map" element={<InteractiveMap />} />  */}
              </Routes>
            </div>
            <Footer />
            {/* <CookieConsent /> */}
          </div>
        </Router>
      </HelmetProvider>
    </UserProvider>
  );
};

export default App;
