import React from 'react';
import { Helmet } from 'react-helmet-async';
import './App.css';
import './components/documentation/documentation.css';

const ShutdownPage = () => {
  return (
    <div className="documentation-page">
      <Helmet>
        <title>Documentation - Verran Codex</title>
        <meta
          name="description"
          content="Explore the API documentation for Ashes of Creation, provided by Verran Codex. Find endpoints, parameters, and example usages."
        />
        <link rel="canonical" href="https://verrancodex.com/" />
      </Helmet>
      <div className="endpoint-content">
        <p>***</p>
        <h2>Verran Codex is shutting down!</h2>
        <p>
          I learned so much from this project and appreciate everyone who stepped up to assist in creating the codex!
        </p>
        <p>
          A very similar site{' '}
          <a href="https://ashescodex.com" target="_blank" rel="noopener noreferrer">
            Ashes Codex
          </a>{' '}
          is taking up the mantle.<br />
          You can find all your database, interactive map, and class testing needs met there!
        </p>
        <p>
          If you have any questions/concerns please feel free to reach out <strong>@wikimaster</strong> on Discord!
        </p>
      </div>
    </div>
  );
};

export default ShutdownPage;
