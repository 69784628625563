import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    const fetchAuthStatus = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/status`, { withCredentials: true });
        const authUser = response.data;

        if (authUser && authUser.id) {
          const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/user-info`, { withCredentials: true });
          const userInfo = userResponse.data.user || null;
          setUser(userInfo);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error fetching auth status', error);
        setUser(null);
      }
    };

    fetchAuthStatus();
  }, []);

  useEffect(() => {
    // Save user data to localStorage whenever it changes
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  // Listen for storage events to update user state in other tabs
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'user') {
        setUser(event.newValue ? JSON.parse(event.newValue) : null);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
