import React from 'react';
import '../App.css';
import koFiButton from '../data/ko-fi-button.png'; 
import gifIcon from '../data/ashes-logo.gif'; 
import './footer.css';

const Footer = () => {
  return (
    <>
      {/* Static Footer */}
      <footer className="static-footer">
        <div className="static-footer-content">
          {/* Left Links Section */}
          {/* <div className="static-footer-links">
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/documentation">API Documentation</a>
            <a href="/terms-of-service">Terms of Service</a>
          </div> */}

          {/* Centered Copyright Section */}
          <p className="copyright-text">
            &copy; Verran Codex 2024, Various assets/images owned by Intrepid Studios.
          </p>

          {/* Placeholder for future right-aligned content */}
          <div className="static-footer-right"></div>
        </div>
      </footer>

      {/* Floating Footer */}
      <div className="footer">
        <a href="https://ko-fi.com/wikimaster" target="_blank" rel="noopener noreferrer">
          <img src={koFiButton} alt="Support me on Ko-fi" className="kofi-button" />
        </a>
        <a href="https://ashesofcreation.com/r/K5CXXYMYQZ2SF3UQ" target="_blank" rel="noopener noreferrer">
          <img src={gifIcon} alt="Ashes of Creation Referral" className="gif-button" />
        </a>
      </div>
    </>
  );
};

export default Footer;
